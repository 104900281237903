import { createAction, props } from "@ngrx/store";
import { PolicyModel } from "./policy.model";

export const fetchPolicyData = createAction('[Data] Fetch Policy');
export const fetchPolicySuccess = createAction('[Data] Fetch Policy Success',props<{ Policy: PolicyModel[] }>());
export const fetchPolicyFailure = createAction('[Data] Fetch Policy Failure', props<{ error: string }>());

// Add Policy Data
export const addPolicy = createAction(
    '[Data] Add Policy',
    props<{ newData: PolicyModel }>()
);
export const addPolicySuccess = createAction(
    '[Data] Add Policy Success',
    props<{ newData: PolicyModel }>()
);
export const addPolicyFailure = createAction(
    '[Data] Add Policy Failure',
    props<{ error: string }>()
);

// Update Policy Data
export const updatePolicy = createAction(
    '[Data] Update Policy',
    props<{ updatedData: PolicyModel }>()
);
export const updatePolicySuccess = createAction(
    '[Data] Update Policy Success',
    props<{ updatedData: PolicyModel }>()
);
export const updatePolicyFailure = createAction(
    '[Data] Update Policy Failure',
    props<{ error: string }>()
);


// Delete Policy Data
export const deletePolicy = createAction(
    '[Data] Delete Policy',
    props<{ id: string }>()
);
export const deletePolicySuccess = createAction(
    '[Data] Delete Policy Success',
    props<{ id: string }>()
);
export const deletePolicyFailure = createAction(
    '[Data] Delete Policy Failure',
    props<{ error: string }>()
);
